<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-08-24 13:38:16
-->
<template>
	<div class="miniManage">
		<common-title :title="'小程序首页图'" />
		<div class="miniManage-content">
			<div class="miniManage-banner">
				<el-image fit="cover" class="miniManage-banner-img" :src="miniUrl" :preview-src-list="[miniUrl]">
				</el-image>
				<!-- <img :src="miniUrl" class=""> -->
				<span @click="upLoadFile" class="miniManage-btn">更换</span>
			</div>
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
	</div>
</template>

<script>
	import { imgMiniprogram, changeImgMiniprogram } from "@/api/system";
	import {
		upLoadFile
	} from "@/api/OSS";
	export default {
		data() {
			return {
				miniUrl: "",
				upLoadUrl: "",
				limitSize: 5,
				fileName: "",
			};
		},
		mounted() {
			this.getMiniprogramImg()
		},
		methods: {
			getMiniprogramImg() {
				imgMiniprogram().then((res) => {
					if (res.code == 0) {
						if(res.data) {
							this.miniUrl = res.data.accessUrl
						}
					}
				})
			},
			upLoadFile(scope) {
				this.upLoadData = scope.row
				document.querySelector(".uploadImage input").click();
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$message.error("上传文件大小不能超过 5MB!");
					return false;
				}
				let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				this.fileName = file.name;
				if (fileSuffix != "png" && fileSuffix != "jpg" && fileSuffix != "jpeg") {
					this.$message({
						message: "上传文件只能是jpg、png格式!",
						type: "warning"
					});
					return false;
				}
			},
			handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				const formData = new FormData();
				formData.append('file', data.file);
				upLoadFile(formData).then((res) => {
					console.log("上传成功", res.data.data.accessUrl)
					changeImgMiniprogram({path: res.data.data.accessUrl}).then((res) => {
						if (res.code == 0) {
							this.$message({
								message: "图片更换成功~",
								type: "success"
							});
							this.getMiniprogramImg()
						}
					})
				})
			},
		},
	};
</script>

<style lang="scss" scoped>
	.miniManage {
		.miniManage-content {
			padding: 0 20px 20px 20px;

			.miniManage-banner {
				width: 375px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.miniManage-banner-img {
					width: 375px;
					max-height: 145px;
					height: auto;
				}

				.miniManage-btn {
					width: 96px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border: 1px solid $theme-color;
					border-radius: 19px;
					font-size: 16px;
					font-family: PingFang SC;
					font-weight: 400;
					color: $theme-color;
					cursor: pointer;
					text-align: center;
					margin-top: 20px;
				}
			}

		}
	}
</style>
